import { apiRootURL } from "./defaultSettings";
export const DOCUMENT_TITLE = "Nodeauto";

export const PROXY_PORT = {
    Opel: 3001,
    "07ZR": 4002,
    Michelin: 4003,
    Ford: 3004,
    RRG: 3005,
    GtEstimate: 8889,
};

export const dockApps = [
    "citroen",
    "ds",
    "opel",
    "peugeot",
    "misterauto",
    "eurorepar",
    "actia",
];

export const iframes = {
    citroen: "https://www.citroen.fr/accueil.html",
    ds: "https://www.dsautomobiles.com/fr/accueil.html",
    eurorepar: "https://mvp-cloudio.ia.parts/index.php/apps/analytics/",
    misterauto: "https://www.mister-auto.com/",
    opel: process.env.REACT_APP_PROXY_SERVER_HOST + ":" + PROXY_PORT.Opel,
    peugeot: "https://www.peugeot.fr/accueil.html",
    actia: "https://www.actia.com/fr",
    back: "https://mvp-cloudio.ia.parts/index.php/settings/admin/serverinfo",
    diag: "https://run.ia.parts",
    erp: "https://mvp-cloudio.ia.parts/",
    store: "https://mvp-cloudio.ia.parts/index.php/settings/apps",
    visio: "https://meetbox.ia.parts",
    com: "https://mvp-cloudio.ia.parts/index.php/apps/announcementcenter/",
    help: "https://mvp-cloudio.ia.parts/index.php/apps/circles/",
    tecrmi: "https://oem1.ia.parts",
};

export const iframePartSelector = {
    fr: "https://mvp-cloudio.ia.parts/index.php/apps/analytics/",
    en: "https://mvp-cloudio.ia.parts/index.php/apps/analytics/",
};

export const iframeCarSelector = {
    fr: "https://mvp.ia.parts/v2/CarSelector/?mode=tablette",
    en: "https://mvp.ia.parts/v2/CarSelector/?mode=tablette",
    es: "https://mvp.ia.parts/v2/CarSelector/?mode=tablette",
};

export const iframeContactSelector = {
    fr: "https://mvp-cloudio.ia.parts/",
    en: "https://mvp-cloudio.ia.parts/",
    es: "https://mvp-cloudio.ia.parts/",
};

export const DIAG_OTHER_BRANDS = [
    { name: "C@iA", url: "https://run.ia.parts" },
    { name: "C@iA", url: "https://run.ia.parts" },
    { name: "C@iA", url: "https://run.ia.parts" },
    { name: "C@iA", url: "https://run.ia.parts" },
];

export const PLUS_MENU_APPS = [
    {
        id: 0,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 1,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 2,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
];

export const WORDPRESS_URL = "https://wp.nodeauto.ooo";
type RseSlide = { iframeURL?: string; imgSrc: string };

const WP_CONTENT_PATH = "https://wp.n.parts/mvp/wp-content/uploads";
export const RSE_SLIDES: RseSlide[] = [
    {
        iframeURL:
            "https://wp.n.parts/mvp/?3d-flip-book=ouverture-de-pap-gennevilliers-le-samedi-matin",
        imgSrc: `${WP_CONTENT_PATH}/2021/05/ouverture-pap-gene.png`,
    },
    {
        iframeURL:
            "https://wp.n.parts/mvp/?3d-flip-book=nouveau-psd-skf-en-stock-sur-la-plateforme-de-fresnes",
        imgSrc: `${WP_CONTENT_PATH}/2021/05/Nouveau-PSD-SKF-en-stock-sur-la-Plateforme-de-Fresnes.png`,
    },
    {
        iframeURL:
            "https://wp.n.parts/mvp/?3d-flip-book=journal-groupe-mai-2021",
        imgSrc: `${WP_CONTENT_PATH}/2021/05/JOURNAL-GROUPE_MAI2021-SLIDE-3_Page_1-scaled.jpg`,
    },
];

export const USE_KEYCLOAK = true;

export const LEVAM_URL = "https://oem1.ia.parts/oem_nodeauto.html";
